import React from "react"
import Fade from "react-reveal/Fade"

const Slider = () => {
  return (
    <Fade right>
      <div className="collaborations-container-slider">
        <div className="collaborations-container-slider-gradient-left">
          <div className="collaborations-container-slider-track">
            <div className="collaborations-container-slider-track-item">
              <iframe
                id="iframe"
                title="1"
                src="https://www.instagram.com/p/BWFh2l5g7pm/embed"
                frameBorder="0"
                scrolling="no"
                allowtransparency="true"
              ></iframe>
            </div>
            <div className="collaborations-container-slider-track-item">
              <iframe
                title="2"
                src="https://www.instagram.com/p/ByDXBJagw9v/embed"
                frameBorder="0"
                scrolling="no"
                allowtransparency="true"
              ></iframe>
            </div>
            <div className="collaborations-container-slider-track-item">
              <iframe
                title="3"
                src="https://www.instagram.com/p/BySzRpeAMob/embed"
                frameBorder="0"
                scrolling="no"
                allowtransparency="true"
              ></iframe>
            </div>
            <div className="collaborations-container-slider-track-item">
              <iframe
                title="4"
                src="https://www.instagram.com/p/Byk1g2YA847/embed"
                frameBorder="0"
                scrolling="no"
                allowtransparency="true"
              ></iframe>
            </div>
            <div className="collaborations-container-slider-track-item">
              <iframe
                title="5"
                src="https://www.instagram.com/p/BYr615plvpD/embed"
                frameBorder="0"
                scrolling="no"
                allowtransparency="true"
              ></iframe>
            </div>
            <div className="collaborations-container-slider-track-item">
              <iframe
                title="6"
                src="https://www.instagram.com/p/BYXKUMWlTBy/embed"
                frameBorder="0"
                scrolling="no"
                allowtransparency="true"
              ></iframe>
            </div>
            <div className="collaborations-container-slider-track-item">
              <iframe
                title="7"
                src="https://www.instagram.com/p/BY99YXylMbu/embed"
                frameBorder="0"
                scrolling="no"
                allowtransparency="true"
              ></iframe>
            </div>
            <div className="collaborations-container-slider-track-item">
              <iframe
                title="1"
                src="https://www.instagram.com/p/BWFh2l5g7pm/embed"
                frameBorder="0"
                scrolling="no"
                allowtransparency="true"
              ></iframe>
            </div>
            <div className="collaborations-container-slider-track-item">
              <iframe
                title="2"
                src="https://www.instagram.com/p/ByDXBJagw9v/embed"
                frameBorder="0"
                scrolling="no"
                allowtransparency="true"
              ></iframe>
            </div>
            <div className="collaborations-container-slider-track-item">
              <iframe
                title="3"
                src="https://www.instagram.com/p/BySzRpeAMob/embed"
                frameBorder="0"
                scrolling="no"
                allowtransparency="true"
              ></iframe>
            </div>
            <div className="collaborations-container-slider-track-item">
              <iframe
                title="4"
                src="https://www.instagram.com/p/Byk1g2YA847/embed"
                frameBorder="0"
                scrolling="no"
                allowtransparency="true"
              ></iframe>
            </div>
            <div className="collaborations-container-slider-track-item">
              <iframe
                title="5"
                src="https://www.instagram.com/p/BYr615plvpD/embed"
                frameBorder="0"
                scrolling="no"
                allowtransparency="true"
              ></iframe>
            </div>
            <div className="collaborations-container-slider-track-item">
              <iframe
                title="6"
                src="https://www.instagram.com/p/BYXKUMWlTBy/embed"
                frameBorder="0"
                scrolling="no"
                allowtransparency="true"
              ></iframe>
            </div>
            <div className="collaborations-container-slider-track-item">
              <iframe
                title="7"
                src="https://www.instagram.com/p/BY99YXylMbu/embed"
                frameBorder="0"
                scrolling="no"
                allowtransparency="true"
              ></iframe>
            </div>
            <div className="collaborations-container-slider-track-item">
              <iframe
                title="1"
                src="https://www.instagram.com/p/BWFh2l5g7pm/embed"
                frameBorder="0"
                scrolling="no"
                allowtransparency="true"
              ></iframe>
            </div>
            <div className="collaborations-container-slider-track-item">
              <iframe
                title="2"
                src="https://www.instagram.com/p/ByDXBJagw9v/embed"
                frameBorder="0"
                scrolling="no"
                allowtransparency="true"
              ></iframe>
            </div>
            <div className="collaborations-container-slider-track-item">
              <iframe
                title="3"
                src="https://www.instagram.com/p/BySzRpeAMob/embed"
                frameBorder="0"
                scrolling="no"
                allowtransparency="true"
              ></iframe>
            </div>
            <div className="collaborations-container-slider-track-item">
              <iframe
                title="4"
                src="https://www.instagram.com/p/Byk1g2YA847/embed"
                frameBorder="0"
                scrolling="no"
                allowtransparency="true"
              ></iframe>
            </div>
            <div className="collaborations-container-slider-track-item">
              <iframe
                title="5"
                src="https://www.instagram.com/p/BYr615plvpD/embed"
                frameBorder="0"
                scrolling="no"
                allowtransparency="true"
              ></iframe>
            </div>
            <div className="collaborations-container-slider-track-item">
              <iframe
                title="6"
                src="https://www.instagram.com/p/BYXKUMWlTBy/embed"
                frameBorder="0"
                scrolling="no"
                allowtransparency="true"
              ></iframe>
            </div>
            <div className="collaborations-container-slider-track-item">
              <iframe
                title="7"
                src="https://www.instagram.com/p/BY99YXylMbu/embed"
                frameBorder="0"
                scrolling="no"
                allowtransparency="true"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </Fade>
  )
}

export default Slider
