import React from "react"
import Reveal from "react-reveal/Reveal"
import Slider from "./slider"
import Stickers from "./stickers"

const Collaborations = () => {
  return (
    <Reveal duration={750}>
      <section className="collaborations-container mod-collaborations">
        <h3>Collaborations</h3>
        <Slider />
        <Stickers />
      </section>
    </Reveal>
  )
}
export default Collaborations
