import React from "react"
import { Link } from "gatsby"
import Reveal from "react-reveal/Reveal"
import Img from "gatsby-image"

const Grid = props => {
  return (
    <Reveal duration={750}>
      <section className="grid">
        <h3>{props.title}</h3>
        <table className="desktop">
          <tbody>
            <tr>
              {props.data.edges.slice(0, 3).map((data, i) => (
                <td key={i}>
                  <Link className="item" to={data.node.frontmatter.url}>
                    <Img
                      fadeIn={true}
                      fluid={data.node.frontmatter.image.childImageSharp.fluid}
                    />
                    <h2>{data.node.frontmatter.title}</h2>
                  </Link>
                </td>
              ))}
            </tr>
            <tr>
              {props.data.edges.slice(3).map((data, i) => (
                <td key={i}>
                  <Link className="item" to={data.node.frontmatter.url}>
                    <Img
                      fadeIn={true}
                      fluid={data.node.frontmatter.image.childImageSharp.fluid}
                    />
                    <h2>{data.node.frontmatter.title}</h2>
                  </Link>
                </td>
              ))}
            </tr>
          </tbody>
        </table>

        <table className="mobile">
          <tbody>
            <tr>
              {props.data.edges.slice(0, 2).map((data, i) => (
                <td key={i}>
                  <Link className="item" to={data.node.frontmatter.url}>
                    <Img
                      fadeIn={true}
                      fluid={data.node.frontmatter.image.childImageSharp.fluid}
                    />
                    <h2>{data.node.frontmatter.title}</h2>
                  </Link>
                </td>
              ))}
            </tr>
            <tr>
              {props.data.edges.slice(2, 4).map((data, i) => (
                <td key={i}>
                  <Link className="item" to={data.node.frontmatter.url}>
                    <Img
                      fadeIn={true}
                      fluid={data.node.frontmatter.image.childImageSharp.fluid}
                    />
                    <h2>{data.node.frontmatter.title}</h2>
                  </Link>
                </td>
              ))}
            </tr>
            <tr>
              {props.data.edges.slice(4).map((data, i) => (
                <td key={i}>
                  <Link className="item" to={data.node.frontmatter.url}>
                    <Img
                      fadeIn={true}
                      fluid={data.node.frontmatter.image.childImageSharp.fluid}
                    />
                    <h2>{data.node.frontmatter.title}</h2>
                  </Link>
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </section>
    </Reveal>
  )
}

export default Grid
