import React, { Fragment } from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout/layout"

import Grid from "../components/showcase/grid"
import Collaborations from "../components/showcase/collaborations"

const VisualArtsPage = props => {
  return (
    <Fragment>
      <Layout>
        <Img
          fadeIn={true}
          className="main-image-wrapper"
          fluid={props.data.main.childImageSharp.fluid}
        />
        <h1>Visual Arts</h1>
        <h2>a creative side</h2>
        <p>
          From a young age, I have immersed myself in various forms of visual
          arts, utilizing a DSLR camera as my creative tool. I have maintained a
          continuous passion for producing visually captivating content, both
          for personal projects and for sharing on social media platforms.
          Below, you will find a curated showcase of my artwork, encompassing
          years of personal and commercial experience, as well as collaborations
          on social media platforms as a content creator.
        </p>
        <div></div>
        <Grid title={"Gallery"} data={props.data.gallery} />
        <Collaborations />
      </Layout>
    </Fragment>
  )
}

export default VisualArtsPage

export const visualArtsImage = graphql`
  fragment visualArtsImage on File {
    childImageSharp {
      fluid(maxWidth: 900) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const data = graphql`
  query {
    main: file(relativePath: { eq: "media/main/visualarts.jpg" }) {
      ...visualArtsImage
    }

    gallery: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/content/gallery/" } }
    ) {
      edges {
        node {
          html
          frontmatter {
            title
            url
            image {
              childImageSharp {
                fluid(maxWidth: 900) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
