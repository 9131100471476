import React from "react"

import ritzcarlton from "../../media/assets/collaborations/ritzcarlton.png"
import noodcases from "../../media/assets/collaborations/noodcases.png"
import plusminus from "../../media/assets/collaborations/plusminus.png"
import sleeksupply from "../../media/assets/collaborations/sleeksupply.png"
import groovengo from "../../media/assets/collaborations/groovengo.png"
import orbitkey from "../../media/assets/collaborations/orbitkey.png"

const Stickers = () => {
  return (
    <div className="collaborations-container-stickers">
      <img alt="ritzcarlton" src={ritzcarlton} />
      <img alt="noodcases" src={noodcases} />
      <img alt="plusminus" src={plusminus} />
      <img alt="sleeksupply" src={sleeksupply} />
      <img alt="groovengo" src={groovengo} />
      <img alt="orbitkey" src={orbitkey} />
    </div>
  )
}

export default Stickers
